import React from 'react';
import xiaoxi from "./photos/xiaoxi.png";
import alexandre from "./photos/alexandre.jpeg";
import camille from "./photos/camille.png";
import giulia from "./photos/giulia.jpg";
import moritz from "./photos/moritz.png";
import sylvain from "./photos/sylvain.png";
import vinay from "./photos/vinay.jpeg";
import aldo from "./photos/aldo.png";
const Team = () => {

    const people = [
    {
      name: 'Xiaoxi Wei',
      bio:
        "Xiaoxi Wei is a PhD student working on Brain-Computer Interfaces at Brain \& Behaviour Lab, Imperial College London. Xiaoxi's research interests focus on brainwave decoding and Brain-Computer Interfaces with machine learning to help people, especially paralyzed patients, control their surroundings with the mind. Xiaoxi is currently working on cross-subject transfer learning methods in EEG decoding.",
      email: 'xiaoxi.wei18@imperial.ac.uk',
      pic_url: xiaoxi
    },
    {
      name: 'Aldo Faisal',
      bio:
        "Aldo Faisal is the Professor of AI \& Neuroscience at the Dept. of Bioengineering and the Dept. of Computing at Imperial College London. His work focusses on Machine Learning for neurotechnology, neurology and critical care applications. He is a UKRI Turing AI Fellow in Machine Learning for Healthcare and Director of the UKRI Centre for Doctoral Training in AI for Healthcare. He has over 20 years expertise in human interfacing and hosted hackathons.",
      pic_url: aldo
      
    },
    {
      name: 'Sylvain Chevallier',
      bio:
        "Sylvain Chevallier, PhD, is an associate professor at University Paris-Saclay, working on machine learning and smooth manifold optimization approaches for brain-computer interfaces. He is maintaining MOABB, a benchmark framework for fair and reproducible evaluation of ML pipelines in BCI, and board member of the French Cortico association, that promote ethical and interoperable BCI usage.  ",
        pic_url: sylvain
    },
    {
      name: 'Alexandre Gramfort',
      bio:
        "Alexandre Gramfort is a senior research scientist at INRIA, Université Paris-Saclay, France. His field of expertise is signal processing, statistical machine learning and scientific computing applied primarily to functional brain imaging data (EEG, MEG, fMRI). He has coauthored more than 30 journal papers and 50 conference papers since 2009. He is a core developer of the Scikit-Learn software and MNE-Python software.",
      pic_url: alexandre
    },
    {
      name: 'Giulia Luise',
      bio:
        "Giulia Luise has recently obtained her PhD in Machine Learning at UCL, London. Her work so far has focused on the interplay between optimal transport and machine learning. She is now a postdoctoral researcher at Imperial College, where she is working on reinforcement learning, targeting applications in healthcare. ",
      pic_url: giulia
    },
    {
      name: 'Moritz Grosse-Wentrup',
      bio:
        "Moritz Grosse-Wentrup is full professor and head of the Research Group Neuroinformatics at the University of Vienna. He develops machine learning algorithms that provide insights into how large-scale neural activity gives rise to (disorders of) cognition, and applies algorithms in the domain of cognitive neural engineering, e.g., to build BCI for communication with paralyzed patients, design closed-loop neural interfaces for stroke rehabilitation, and develop personalized brain stimulation paradigms.",
      pic_url: moritz
    },
    {
      name: 'Camille Jeunet',
      bio:
        "Camille Jeunet is a tenured research scientist at CNRS, France. In 2021, she has rejoined the University of Bordeaux where she leads an interdisciplinary research that aims to understand and improve BCI user-training.She is interested in using EEG-based BCIs to improve cognitive and motor skills in athletes and in stroke patients and patients with Parkinson disease. She is a board member of the French BCI association, CORTICO. ",
      pic_url: camille
  
      
    },
    {
      name: 'Vinay Jayaram',
      bio:
        "Vinay Jayaram is a research scientist in non-invasive neural interfaces in New York City. After finishing his PhD in machine learning for EEG-based BCIs at the University of Tuebingen in 2018, he worked at CTRL-Labs and subsequently at Facebook Reality Labs. His interests are in machine learning for closed-loop human-machine interfaces. ",
      pic_url: vinay
    },
    // More people...
    
  ]

    return (
        <div id="team">
      <div className="bg-gray-50">
        <div className="mx-auto py-6 px-4 lg:px-32 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <h2 className="text-3xl text-indigo-500 text-center font-extrabold tracking-tight sm:text-4xl">The Team</h2>
            <div className="mx-auto px-4 lg:px-32 max-w-7xl sm:px-6 lg:px-8">
                <h2 className="text-center text-indigo-500">To get in touch please contact <a className="font-bold" href="mailto:competition@beetl.ai">competition@beetl.ai</a></h2>
            </div>
            <ul className="px-1 lg:px-1 space-y-20 lg:grid lg:grid-cols-1 lg:items-start lg:gap-x-8 lg:gap-y-24 lg:space-y-0">

            <div class="grid grid-cols-1 gap-x-8 gap-y-12 md:grid-cols-2">
              {people.map((person) => (
                  <li key={person.name}>
                  <div class="grid grid-cols-3 gap-4">
                    <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                        {<img className="w-44 h-56 object-cover shadow-lg rounded-lg" src={person.pic_url} alt="" />}
                    </div>
                    <div className="w-full col-span-2 space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:gap-8">
                      <div className="sm:col-span-2">
                        <div className="space-y-4">
                          <div className="text-lg leading-6 font-medium space-y-1">
                            <h3 className="text-center">{person.name}</h3>
                            {/* <p className="text-indigo-600">{person.role}</p> */}
                          </div>
                          <div className="flex justify-center">
                            <p className="text-gray-500 text-sm max-w-md lg:max-w-lg text-center">{person.bio}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </li>
              ))}
            </div>

            </ul>
          </div>
        </div>
      </div>
            
        </div>
    );
};

export default Team;