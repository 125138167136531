import React from 'react';
import NeurIPSlogo from './NeurIPSlogo.png';
import NeurIPSlogo2 from './NeurIPSlogo2.png';
import Figure0 from './figure0.png';
const ChallengeIntroduction = () => {
    return (
        <div id="introduction" className="bg-gray-700 p-8 pb-16">
            <div class="flex justify-center space-x-4">
                <div><a href="https://neurips.cc/Conferences/2021/CompetitionTrack" target="_blank"><img className="h-24 w-auto sm:h-28 bg-gray-200" src={NeurIPSlogo} alt="" /></a></div>
                <div><a href="https://neurips.cc/Conferences/2021/CompetitionTrack" target="_blank"><img className="h-24 w-auto sm:h-28 bg-gray-200" src={NeurIPSlogo2} alt="" /></a></div>
            </div>
            <h2 className="font-bold text-2xl p-8 text-white text-center">Introduction</h2>
            <div className="grid grid-cols-1  text-sm sm:text-base gap-4 gap-y-6 max-w-md sm:max-w-xl lg:max-w-4xl text-gray-100 justify-items-center p-2 m-auto">
                
                <p className="">Transfer learning and meta-learning offer some of the most promising avenues to accelerate healthcare and consumer technologies driven by biosignal data, while these machine learning fields would benefit significantly from a stimulus real-world benchmark with immediate practical application. </p>
                <p className="">We pick electroencephalography (EEG),  which are broadly considered one of the most promising ways to non-invasively read out the human brain for diagnostic and human interfacing purposes. EEG reflects all the features that make biosignal understanding a hard problem, as it consists of multi-dimensional time-series data that suffers from user and  session-dependent non-stationary and poor signal-to-noise ratio. A solution to these issues must be robust across anatomical and neurological differences between individuals, EEG headset designs, changes in ambient electrical noise, even different electrode placements relative to the scalp and similar factors.</p>
                <div className="bg-white w-12/12 py-4 rounded-lg">
                    <img className="object-contain w-full h-auto m-auto lg:max-h-96" alt="" src={Figure0} />
                </div>
                
                <p className="">While EEG hardware has steadily evolved, a trustworthy and data-efficient way of turning EEG data into reliable decoding of brain state and intention is still missing. This challenge cannot be easily overcome with simply better sensor technology, but may be leapfrogged by algorithmic developments. </p>
                <p className="">We set out two challenging tasks to stimulate such algorithmic innovation. Task 1 is centred on transfer learning in the field of medical diagnostics, addressing automatic sleep stage annotation. The challenge lies in transferring from a large control population data set to clinically relevant cohorts with very little training data (transfer across subjects). Task 2 is centred on transfer learning for Brain-Computer Interfaces (BCI), addressing motor imagery decoding. The challenge lies in transferring from multiple data sets which use different EEG setups comprising hundreds of users, to a set of new users that need to be up and running with only minutes worth of training data (transfer across subjects and data sets).</p>

            </div>
        </div>
    );
}  
export default ChallengeIntroduction;