/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import logo from './logo.svg';
import {Link} from 'react-router-dom';

const navigation = [
{ name: 'Introduction', href: '/introduction' },
{ name: 'The Challenge', href: '/challenge' },
{ name: 'Data', href: '/data' },
{ name: 'Schedule', href: '/schedule' },
{ name: 'Code and Evaluation', href: '/code' },
{ name: 'Rules', href: '/Rules' },
{ name: 'Team', href: '/team' },
{ name: 'Prize and NeurIPS', href: '/prizes' },
]

export default function Example() {
return (
<div className="relative bg-gray-50 overflow-hidden">
    <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
        <div className="relative h-full max-w-7xl mx-auto">
            <svg className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
                width={404} height={784} fill="none" viewBox="0 0 404 784">
            </svg>
            <svg className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
                width={404} height={784} fill="none" viewBox="0 0 404 784">
            </svg>
        </div>
    </div>

    <div className="relative pt-6 pb-16 sm:pb-24 bg-gradient-to-bl from-white to-indigo-100">
        <Popover>
            {({ open }) => (
            <>
                <div className="max-w-7xl mx-auto px-4 md:px-6">
                    <nav className="relative flex items-center justify-between sm:h-10 md:justify-center"
                        aria-label="Global">
                        <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                            <div className="flex items-center justify-between w-full md:w-auto">
                                <Link to="/">
                                    <span className="sr-only">BEETL</span>
                                    <img className="h-12 w-auto" src={logo} alt="" />
                                    
                                </Link>
                                <div className="-mr-2 h-28 flex items-center md:hidden">
                                    <Popover.Button
                                        className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Open main menu</span>
                                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:flex md:space-x-5">
                            {navigation.map((item) => (
                            <Link key={item.name} to={item.href}
                                className="font-medium text-gray-500 hover:text-indigo-500 hover:bg-gray-100 p-2 rounded-md">
                                {item.name}
                            </Link>
                            ))}
                        </div>
                    </nav>
                </div>

                <Transition show={open} as={Fragment} enter="duration-150 ease-out" enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100" leave="duration-100 ease-in" leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95">
                    <Popover.Panel focus static
                        className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                        <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="px-5 pt-4 flex items-center justify-between">
                                <div>
                                    <Link to="/">
                                        <img className="h-8 w-auto"
                                        src={logo} alt="" />
                                    </Link>
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button
                                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Close menu</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="px-2 pt-2 pb-3">
                                {navigation.map((item) => (
                                <a key={item.name} href={item.href}
                                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-900 hover:bg-gray-50">
                                    {item.name}
                                </a>
                                ))}
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </>
            )}
        </Popover>

        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
            <div className="text-center">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block xl:inline">NeurIPS 2021 BEETL Competition:</span>{' '}
                    <span className="block xl:inline text-indigo-500">Benchmarks for EEG Transfer Learning</span>
                </h1>
            </div>
        </main>
    </div>
</div>
)
}