import { CheckIcon, ThumbUpIcon, UserIcon } from '@heroicons/react/solid'

const timeline = [
{
id: 1,
content: 'Website launch and first announcements of competition rules. Begin advertising the competition.',
href: '#',
date: 'May 31, 2021',
datetime: '2021-05-31',
icon: CheckIcon,
iconBackground: 'bg-green-500',
},
{
id: 2,
content: 'Training sets and leader board test sets release, together with the start kits in the Code page.',
href: '#',
date: 'July 9, 2021',
datetime: '2021-07-9',
icon: CheckIcon,
iconBackground: 'bg-green-500',
},
{
id: 3,
content: 'Competition registration begins, predictions and code upload begins, leader board website activated. Registration details will be in the Code&Evaluation page.',
href: '#',
date: 'July 9 - September 10, 2021',
datetime: '2021-07-09',
icon: CheckIcon,
iconBackground: 'bg-green-500',
},
{
id: 4,
content: 'Final test samples for scoring release, submission of documents available.',
href: '#',
date: 'Sep 11, 2021',
datetime: '2021-09-11',
icon: CheckIcon,
iconBackground: 'bg-green-500',
},
{
id: 5,
content: 'Final submission period.',
href: '#',
date: 'September 11 - October 1, 2021',
datetime: '2021-09-11',
icon: CheckIcon,
iconBackground: 'bg-green-500',
},
{
id: 6,
content: 'Submissions evaluation by organisers.',
href: '#',
date: 'October 2 - October 30, 2021',
datetime: '2021-10-02',
icon: CheckIcon,
iconBackground: 'bg-green-500',
},
{
id: 7,
content: 'Competition results announcement.',
href: '#',
date: 'October 31, 2021',
datetime: '2021-10-31',
icon: CheckIcon,
iconBackground: 'bg-green-500',
},
{
id: 8,
content: 'Competition results announced on Codalab - Leaderboard for Final testing phase available.',
href: '',
date: 'October 31, 2021',
datetime: '2021-10-31',
icon: CheckIcon,
iconBackground: 'bg-green-500',
},
{
id: 9,
content: 'BEETL Workshop in NeurIPS 2021 (Day 4 Competition Track, registration for NeurIPS2021 needed).',
href: '',
date: 'December 10, 2021 11:00 - 14:00 GMT',
datetime: '2021-12-10',
icon: CheckIcon,
iconBackground: 'bg-green-500',
},
]

function classNames(...classes) {
return classes.filter(Boolean).join(' ')
}

export default function Schedule() {
return (
<div className="bg-white pt-8" id="schedule">
    <h1 className="font-bold text-2xl text-center">Schedule</h1>
    <div className="p-8 flex justify-center">
        <ul className="max-w-2xl">
            {timeline.map((event, eventIdx) => (
            <li key={event.id}>
                <div className="relative pb-8">
                    {eventIdx !== timeline.length - 1 ? (
                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                    ) : null}
                    <div className="relative flex space-x-3">
                        <div>
                            <span className={classNames(
                                event.iconBackground, 'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                )}>
                                <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                            <div>
                                <p className="text-sm text-gray-500">
                                    {event.content}{' '}
                                    {/* <a href={event.href} className="font-medium text-gray-900"> */}
                                        {/* {event.target} */}
                                        {/* </a> */}
                                </p>
                            </div>
                            <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                <time dateTime={event.datetime}>{event.date}</time>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            ))}
        </ul>
    </div>
</div>
)
}