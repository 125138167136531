import React from 'react';
import scoring from "./scoring.png";
import {Link} from "react-router-dom";

<Link to="/Dashboard"> Dashboard </Link>

const Procedure = () => {
return (
<div>
    <div className="flex flex-col justify-center pb-8 pt-16 md:pb-16 bg-gray-50 px-12 py-4" id="code">
        <div className="flex flex-col justify-center mb-8 ">
            <h2 className="font-bold text-2xl text-center py-4">Code and Tutorial</h2>
            <p className="text-gray-500 max-w-2xl m-auto">We release start kits for the two tasks at <a href="https://github.com/XiaoxiWei/NeurIPS_BEETL" target="_blank" className='text-blue-700'>https://github.com/XiaoxiWei/NeurIPS_BEETL</a>.</p>
        </div>

        <div className="flex flex-col justify-center mb-8 ">
            <h2 className="font-bold text-2xl text-center py-4">Registration and Leaderboard</h2>
            <p className="text-gray-500 max-w-2xl m-auto text-center">We will run our competition registration and label files submission on <a href="https://competitions.codalab.org/competitions/33427"  className='text-blue-700' target="_blank">Codalab: NeurIPS 2021 BEETL Competition</a>.</p>
        </div>

        <h2 className="font-bold text-2xl text-center">Procedure</h2>
        <ul className="list-outside block list-disc max-w-2xl text-center m-auto py-4 text-gray-700">
            <li>We will provide training data the true labels and baseline methods with a tutorial at the beginning of
                this competition.</li>
            <li>The first part of the test sample will be released together with training data. Test sets are without
                true labels. This part is the leader board testing set.
            </li>
            <li>After participants have the predictions of the leader board test samples, they can upload the TXT file
                with their predictions to our leader board website to see real-time ranking and scores of each task and
                data sets.
            </li>
            <li>At the final stage of the competition, we will release the test samples for the final ranking.</li>
            <li>Participants upload their predictions to our website together with their code and a 2-page document (in
                paper format with methods, analysis and discussion).
            </li>
            <li>Their final rankings and scores will be released after the competition deadline.
            </li>
        </ul>
        <div className="flex flex-col place-items-center">
            <h2 className="font-bold text-2xl text-center py-4">Evaluation</h2>
            <p className="text-gray-500 max-w-2xl m-auto text-center">There will be two phases of evaluation. The first phase is the leader board evaluation. After participants upload their predictions of the leader board test trials,the website will automatically compare the predictions with true labels, and update the rankings on our websites. The second phase consists in uploading predictions of the final test set to our website, then we will ask participants to email us their code (python are recommended but participants can use any programming language) and documents. We will write emails to ask for code and documents. We will test run their python code to generate labels and compare them with the labels they uploaded, please make sure the labels are reproducible using, for example, fixed random seeds. If the test run labels are not identical to the ones participants uploaded, we may consider cancelling the scores accordingly. In their document, we highly recommend they have a comparison of accuracy with and without their transfer learning approach; and both should present in their code if applicable. Please be AWARE that ranking in the first phase does NOT represent final rankings, we may use different data sets with different distributions in the second phase. Scores for final ranking will be only computed based on the second phase (final test phases for scoring). The final ranking will be the sum score of the two tasks. 
            </p>

            <br></br>
            <p className="text-gray-500 max-w-2xl m-auto text-center">
                If a participant only choose to submit in one Task and not the other, their Final Score will be calculated as follows: their score for the not submitted task will be set to the 0.99 * minimum score achieved by participants that actually submitting in that task. 
            </p>
        </div>
    </div>
    <div>
    </div>
</div>
);
};

export default Procedure;