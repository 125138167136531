import React from 'react';



const Rules = () => {
return (
<div className="flex flex-col justify-center bg-gray-50">
    
    <div className="flex flex-col justify-center ">
        <h2 className="font-bold text-2xl text-center mt-12">Rules</h2>
        <ul className="list-outside list-disc max-w-xl text-center m-auto py-8 text-gray-700">
            <li>Related members from the organising team can participate in the competition but are ineligible for
                prizes.
            </li>
            <li>
                Participants are not allowed to use data sets to pre-train or train their algorithms other than the data provided by the competition, please read our tutorials about data carefully. We will look into the participants' code and their documents.
            </li>
            <li>Any algorithms trying to find true labels by referring to the original public data sets will be regarded as cheating. We will test run participants code and review their documents at the end of the competition.
            </li>
            <li>Participants are allowed to upload 3 times a day to the leader board website.</li>
            <li>We do not limit the programming language but Python is recommended for the committee to test run the
                code.</li>
            <li>We recommend participants to publish their work of this competition and submit pre-prints after the
                competition for communication of science.</li>
            <li>Winning teams of each task will be invited to give talks.</li>
        </ul>
    </div>
    <div className="flex flex-col justify-center mb-16  ">
        <h2 className="font-bold text-2xl text-center py-4">Cheating Prevention</h2>
        <p className="text-gray-500 max-w-2xl m-auto">Since parts of the competition data sets are public, we randomise
            the EEG samples and keep our randomised label confidential. Any algorithms trying to find true labels by
            referring to the original public data sets will be regarded as cheating. We will test run participants' code and review their documents at the end of the competition. **important** Please also read carefully our tutorial code which contents details about how to use data without being regarded as cheating. </p>
    </div>
</div>
);
};

export default Rules;