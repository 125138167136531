import React from 'react';

const PrizesAndSponsors = () => {
return (
<div className=" bg-gray-50 pt-16">
    <div className="grid grid-cols-1 text-sm sm:text-base gap-4 gap-y-8 max-w-md sm:max-w-xl lg:max-w-4xl text-gray-500 justify-items-center p-4 m-auto">

    
    <h2 className="font-bold text-2xl">NeurIPS 2021 BEETL Workshop</h2>
    <ul className="list-inside block list-disc max-w-2xl text-center">
            <li>Results are announced and Talks&Posters are given in <a href="https://neurips.cc/virtual/2021/calendar" target="_blank" className='text-blue-700'>NeurIPS2021</a> (competition track Day 4) the <a href="https://neurips.cc/virtual/2021/competition/22445" target="_blank" className='text-blue-700'> “Benchmarks for EEG Transfer Learning” NeurIPS workshop</a> on 10th December 2021 (registration for NeurIPS2021 needed). </li>

            <li>10th Dec. 10:45 a.m. - 11:05 a.m. GMT - Competition Overview</li>
            <li>10th Dec. 11:04 a.m. - 1:04 p.m. GMT - Competition Workshop: Talks & Panel Discussion</li>
            <li>10th Dec. 1:05 p.m. - 2:05 p.m. GMT - Poster Session in Gathertown</li>
    </ul>
    
    <h2 className="font-bold text-2xl">Prizes</h2>
    <ul className="list-inside block list-disc max-w-2xl text-center">
            <li>Top 3 teams will be awarded 4-digit cash prizes.</li>
            <li>We invited the top 3 contributors in the final overall score to give talks and top 5 to give posters in the NeurIPS BEETL competition workshop.
            </li>
    </ul>


    <div class='w-3/4 text-center'>
        <table class="table-auto w-full">
        <caption class="font-bold text-2xl pb-8">Top 5 winning teams</caption>
            <thead>
                <tr>
                    <th>Team Name</th>
                    <th>Task 1</th>
                    <th>Task 2</th>
                    <th>Overall Score</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>&nbsp;Cogitat</td>
                    <td>&nbsp;65.55</td>
                    <td>&nbsp;76.33</td>
                    <td>&nbsp;141.88</td>
                </tr>
                <tr>
                    <td>&nbsp;wduong</td>
                    <td>&nbsp;68.66</td>
                    <td>&nbsp;71.33</td>
                    <td>&nbsp;139.99</td>
                </tr>
                <tr>
                    <td>&nbsp;ms01</td>
                    <td>65.57</td>
                    <td>&nbsp;59.87</td>
                    <td>&nbsp;125.44</td>
                </tr>
                <tr>
                    <td>&nbsp;nik-sm</td>
                    <td>&nbsp;69.23</td>
                    <td>&nbsp;54.47</td>
                    <td>&nbsp;123.7</td>
                </tr>
                <tr>
                    <td>&nbsp;michaln</td>
                    <td>&nbsp;66.78</td>
                    <td>&nbsp;56.47</td>
                    <td>&nbsp;123.25</td>
                </tr>
            </tbody>
        </table>
        <li>More details about leaderboard in the Codalab competition<a href="https://competitions.codalab.org/competitions/33427#results" target="_blank" className='text-blue-700'> result page</a>  </li>


    </div>

	<h2 className="font-bold text-2xl">Sponsors</h2>
    <p className="px-8 text-gray-500 max-w-xl text-center"> We thank Facebook Reality Labs for sponsoring in competition prizes.</p>
    <p className="px-8 pb-2 text-gray-500 max-w-xl text-center"> The BEETL competition is affiliated with the <a href="https://bcisociety.org/" target="_blank" className='text-blue-700'> BCI Society </a>.</p>
    </div>

</div>


);
};

export default PrizesAndSponsors;