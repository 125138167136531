import Header from './Header';
import './App.css';
import ChallengeIntroduction from './ChallengeIntroduction';
import ChallengeDescription from './ChallengeDescription';
import Data from './Data';
import Team from './Team';
/*import Prizes from './Prizes';*/
import Rules from './Rules';
import Schedule from './Schedule';
import Procedure from './Procedure';
import Prizes from './PrizesAndSponsors'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";


function App() {
  return (
    <Router>
    <div className="App">
      <Header/>
    </div>
      <Switch>
          <Route exact path="/">
            <Redirect to="/introduction" />
          </Route>
          <Route exact path="/introduction">
            <ChallengeIntroduction />
          </Route>
          <Route exact path="/challenge">
            <ChallengeDescription />
          </Route>
          <Route exact path="/schedule">
            <Schedule/>
          </Route>
          <Route exact path="/data">
            <Data />
          </Route>
          <Route exact path="/code">
            <Procedure />
          </Route>
          <Route exact path="/Rules">
            <Rules/>
          </Route>
          <Route exact path="/team">
            <Team/>
          </Route>
          <Route exact path="/prizes">
            <Prizes/>
          </Route>
          
      </Switch>
    </Router>
    
  );
}

export default App;
